
import { defineComponent } from 'vue'
import IOPageHeader from '@/components/IOPage/IOPageHeader.vue'
import IOPageOverview from '@/components/IOPage/OverviewTab/IOPageOverview.vue'
import baseViewMixin from '@/mixins/baseViewMixin'
import { generalMixin } from '@/mixins/generalMixin'
import IOForm from '@/components/TableComponents/Form/IOForm.vue'
import IoFormMixin from '@/mixins/ioFormMixin'
import { Baseline, Instruction, IoBrief } from '../../types/instruction_type'
import { mapMutations } from 'vuex'
import { getIoField } from '../../utils/instructionsUtils'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import OutcomesDialog from '@/components/TableComponents/Outcomes/OutcomesDialog.vue'
import IOPagePerformance from '@/components/IOPage/PerformanceTab/IOPagePerformance.vue'

export default defineComponent({
  name: 'IOPage',
  components: { IOPagePerformance, IOPageOverview, IOPageHeader },
  mixins: [baseViewMixin, generalMixin],
  data () {
    return {
      loading: false,
      ioDatastore: {} as any,
      overview: {},
      performance: {},
      dsp: this.$route.params.dsp,
      ioId: this.$route.params.external_id,
      instructionSortedByIo: this.$store.getters.getInstructionSortedByIo,
      editedIOIndex: this.$store.getters.getEditedIOIndex,
      openTab: 'tab-1'
    }
  },
  async mounted () {
    this.onMounted()
    const data = {
      dsp: this.dsp,
      search: this.ioId
    }
    this.loading = true
    const resInstructions = await this.$apiCaller.getInstructions(data)
    if (this.$apiCaller.isResponseError(resInstructions)) {
      console.warn('Error when calling instruction')
      this.$store.commit('setErrorMessageWithResponse', resInstructions)
      this.loading = false
      this.ioDatastore = {}
    } else {
      // TODO: ioDatastore becomes the first element in res.data to have a dsp equal to this.dsp and an external_id equal to this.ioId
      this.ioDatastore = resInstructions.data[0]
      this.loading = false
    }

    const resOverview = await this.$apiCaller.getIoPageOverview(this.dsp, this.ioId)
    console.log(resOverview)
    if (this.$apiCaller.isResponseError(resOverview)) {
      console.warn('Error when calling instruction')
      this.$store.commit('setErrorMessageWithResponse', resOverview)
      this.loading = false
      this.overview = {}
    } else {
      this.overview = resOverview.data
      this.loading = false
    }

    const resPerformance = await this.$apiCaller.getIoPagePerformance(this.dsp, this.ioId)
    console.log(resPerformance)
    if (this.$apiCaller.isResponseError(resPerformance)) {
      console.warn('Error when calling instruction')
      this.$store.commit('setErrorMessageWithResponse', resPerformance)
      this.loading = false
      this.performance = {}
    } else {
      this.performance = resPerformance.data
      this.loading = false
    }
  },
  methods: {

    openEdit () {
      console.log('edit')
      this.openTab = 'tab-1'
    },
    openLogs () {
      this.openTab = 'tab-3'
    },
    openNotes () {
      this.openTab = 'tab-4'
    },
    openIoFromSearch (search: string) {
      // get route to change external_id to search then reload
      this.$router.replace({
        name: 'ioPage',
        params: { dsp: this.dsp, external_id: search }
      })
      location.reload()
    }
  },
  computed: {
  }
})
