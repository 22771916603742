import { render, staticRenderFns } from "./IOPagePerformancePacing.vue?vue&type=template&id=86960c18&scoped=true"
import script from "./IOPagePerformancePacing.vue?vue&type=script&lang=ts"
export * from "./IOPagePerformancePacing.vue?vue&type=script&lang=ts"
import style0 from "./IOPagePerformancePacing.vue?vue&type=style&index=0&id=86960c18&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86960c18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VSpacer})
