
import { defineComponent } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

export default defineComponent({
  name: 'IOPagePerformanceKpiToOptimize',
  props: ['performance', 'ioDatastore', 'period'],
  methods: {
    formatNumbers (nb: number): string {
      if (nb === null || nb === undefined) {
        return 'N/A'
      }
      if (Math.abs(nb) < 10) {
        return (nb).toFixed(2)
      }
      if (Math.abs(nb) < 1000) {
        return Math.round(nb).toString()
      }
      if (Math.abs(nb) < 1000000) {
        // if the number is a round thousand, we don't want to display the decimal
        if (Math.round(nb / 1000) === Math.round(nb * 10 / 10000)) {
          return Math.round(nb / 1000).toString() + 'K'
        }
        return (nb / 1000).toFixed(1) + 'K'
      }
      if (Math.abs(nb) < 1000000000) {
        // if the number is a round million, we don't want to display the decimal
        if (Math.round(nb / 1000000) === Math.round(nb * 100 / 100000000)) {
          return Math.round(nb / 1000000).toString() + 'M'
        }
        return (nb / 1000000).toFixed(2) + 'M'
      }
      if (Math.abs(nb) >= 1000000000) {
        // if the number is a round billion, we don't want to display the decimal
        if (Math.round(nb / 1000000000) === Math.round(nb * 100 / 100000000000)) {
          return Math.round(nb / 1000000000).toString() + 'B'
        }
        return (nb / 1000000000).toFixed(2) + 'B'
      }
    }
  },
  computed: {
    kpiFromPeriod (): any {
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday
        case 'week':
          return this.performance?.kpi?.last_7_days
        case 'flight':
          return this.performance?.kpi?.flight
        default:
          return this.performance?.kpi?.yesterday
      }
    },
    kpiFromPreviousPeriod (): any {
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday_minus_1
        case 'week':
          return this.performance?.kpi?.last_7_days_minus_1
        case 'flight':
          return this.performance?.kpi?.flight_minus_1
        default:
          return this.performance?.kpi?.yesterday_minus_1
      }
    },
    kpiToOptimize (): any {
      return this.kpiFromPeriod?.kpi_to_optimize ? this.kpiFromPeriod.kpi_to_optimize : '-'
    },
    kpiToOptimizeValue (): any {
      return this.kpiFromPeriod?.kpi_to_optimize_value ? this.formatNumbers(this.kpiFromPeriod.kpi_to_optimize_value) : '-'
    },
    kpiCompareIcon (): any {
      const currentValue = this.kpiFromPeriod?.kpi_to_optimize_value
      const prevValue = this.kpiFromPreviousPeriod?.kpi_to_optimize_value
      return prevValue > this.kpiToOptimizeValue ? 'arrow_drop_up' : prevValue < this.kpiToOptimizeValue ? 'arrow_drop_down' : 'horizontal_rule'
    },
    kpiComparedToPrevious (): any {
      return (this.formatNumbers((this.kpiFromPreviousPeriod?.kpi_to_optimize_value - this.kpiToOptimizeValue) / this.kpiFromPreviousPeriod?.kpi_to_optimize_value * 100)) + '%'
    },
    targetValue (): any {
      return this.ioDatastore?.obj_watcher?.KPI_to_optimize?.value
    },
    displayMaxA (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.max_A)
    },
    displayMaxCutoff (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.max_cutoff)
    }
  }
})
