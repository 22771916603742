
import { defineComponent } from 'vue'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import IOForm from '@/components/TableComponents/Form/IOForm.vue'
import { Baseline, Instruction, IoBrief } from '../../../types/instruction_type'
import ioFormMixin from '@/mixins/ioFormMixin'
import { instructionsCaller } from '@/mixins/instructionsCaller'
import { getIoField } from '../../../utils/instructionsUtils'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'IOPageHeader',
  components: { IOForm, CopyClipboardComponent },
  mixins: [ioFormMixin, instructionsCaller],
  props: ['ioId', 'dsp', 'item'],
  data () {
    return {
      advertiserId: 'Advertiser ID',
      memberId: 'Member ID',
      advertiserName: 'Advertiser name',
      memberName: 'Member name',
      ioName: 'IO name',
      isHoverMember: false,
      isHoverAdvertiser: false,
      isHoverIo: false,
      ioSearch: '',
      instructionSortedByIo: this.$store.getters.getInstructionSortedByIo,
      editedIOIndex: this.$store.getters.getEditedIOIndex,
      openTab: 'tab-1',
      notes: 0
    }
  },
  async mounted () {
    await this.callApi()
  },
  methods: {
    ...mapMutations(['setErrorMessage', 'editStartUpdate', 'editEndUpdate']),
    redirectToMember () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'member' },
        query: { search: this.memberId }
      })
    },
    redirectToAdvertiser () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'advertiser' },
        query: { search: this.advertiserId }
      })
    },
    redirectToIoView () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' },
        query: { search: this.ioId }
      })
    },
    async callApi () {
      console.log(this.ioId, this.advertiserId, this.memberId)
      const search = {
        search: this.ioId,
        dsp: this.dsp
      }
      const response = await this.$apiCaller.getOverviewsWithSearch(search)
      if (!this.$apiCaller.isResponseError(response)) {
        const data = (response as any).data
        if (data.length > 0) {
          this.advertiserId = data[0].advertiser.external_id
          this.memberId = data[0].member.external_id
          this.advertiserName = data[0].advertiser.name
          this.memberName = data[0].member.name
          this.ioName = data[0].name
        }
      } else {
        this.$store.commit('setErrorMessageWithResponse', response)
      }
      const notesResponse = await this.$apiCaller.getInstructionNotes(this.ioId, this.dsp)
      if (!this.$apiCaller.isResponseError(notesResponse)) {
        const data = (notesResponse as any).data
        this.notes = data.length
      } else {
        this.$store.commit('setErrorMessageWithResponse', notesResponse)
      }
    },
    openEdit () {
      this.editItem(this.item)
    },
    openOutcomes () {
      this.$emit('outcomes')
    },
    openLogs () {
      this.$emit('logs')
    },
    openNotes () {
      this.$emit('notes')
    },
    openSearch (event: KeyboardEvent) {
      event.preventDefault()
      console.log('lololol')
      this.$emit('search', this.ioSearch)
    },
    getIOField () {
      return getIoField(this.dsp)
    },
    /**
     * Call the api for the io,
     * update the instruction in the collection
     * and open the IoForm
     * @param item {import('../../../types/instruction_type').IoBrief}
     */
    async editItem (item: any) {
      this.editedIOIndex = item[this.getIOField()]
      // 1. call api for each id
      this.editStartUpdate()
      let updatedInstruction : any[] = []

      const data = {
        dsp: this.dsp,
        [this.getIOField()]: item[this.getIOField()]
      }

      const response = await this.$apiCaller.getInstructions(data)

      if (this.$apiCaller.isResponseError(response)) {
        this.$store.commit('setErrorMessageWithResponse', response)
        this.editEndUpdate()
        return
      }

      updatedInstruction = response.data

      // replace instruction per her updated version
      // 2. replace the instruction per the instruction received
      console.log('this.instructionSortedByIo', this.instructionSortedByIo)
      console.log('this.editedIOIndex', this.editedIOIndex)
      console.log('updatedInstruction', updatedInstruction)

      // 3. open the form
      this.$nextTick(() => {
        // get updated insertion_order
        let updatedItem = [this.item]
        // update id_list
        updatedItem[0].id_list = updatedInstruction.map(item => item.id)
        const ioBrief = updatedItem[0]
        this.openEditForm(ioBrief, updatedInstruction)
        this.editEndUpdate()
      })
    },
    openEditForm (ioBrief: IoBrief<any>, updatedInstructions: Instruction[]) {
      this.$authModule.checkIfRefreshIsNeeded()
      this.$store.commit('setFormStatus', this.$EDIT)
      this.editedInstructionsIO = this.$commonUtils.deepCopy(updatedInstructions)
      this.editedItem = this.$commonUtils.deepCopy(ioBrief)
      console.log('editedItem', this.editedItem)
      console.log('editedInstructionsIO', this.editedInstructionsIO)
      console.log('keyIOForm', this.keyIoForm)
      console.log('dataFormGroupKey', this.dataFormGroupKey)
      console.log('openTab', this.openTab)
      console.log('formTitle', this.formTitle)
      console.log('status', this.$store.getters.getFormStatus)
      console.log('formPartWithError', this.$store.getters.getFormPartWithError)
      this.dialog = true
    },
    save (acknowledgments : any = null, baseline: any = null) {
      if (this.formIsEdit()) {
        // for editing
        console.log('ici ?')
        this.editMultiProcess(acknowledgments, baseline)
      } else {
        // for new item
        this.createMultiProcess(acknowledgments, baseline)
      }
      this.close()
    }, // end method update box
    /**
     * process called when form is in edit
     * emit a event 'delete-and-post-raw'
     * @param acknowledgments {AcknowledgmentInfo[]}
     * @param baseline {Baseline}
     */
    editMultiProcess (acknowledgments: AcknowledgmentInfo[] = null, baseline: Baseline = null) {
      const result = this.prepareEditMultiProcess()
      // TODO: fix the whole IOForm integration
      /* this.deleteAndPostRaw([
        ...result.editedInstru,
        ...result.newInstru
      ], acknowledgments, result.toDeleteInstruId, baseline) */
    }
  },
  computed: {
    formTitle () {
      return this.getFormStatus + ' item'
    },
    getFormStatus () : any {
      return this.$store.getters.getFormStatus
    },
    keyIoForm () : any {
      return this.editedItem.id
    }
  },
  watch: {
  }
})
