
import { defineComponent } from 'vue'
import _ from 'lodash'

export default defineComponent({
  name: 'IOPagePerformancePacing',
  props: ['performance', 'ioDatastore', 'period'],
  data () {
    return {
      ioId: this.$route.params.external_id
    }
  },
  methods: {
    parseDate (date: string): number {
      return new Date(date).getTime()
    },
    formatDate (dateStr: string): string {
      const date = new Date(dateStr)
      return `${date.getMonth()}/${date.getDate()}`
    },
    shortenPacingStatus (status: string): string {
      return status === 'Delivering' ? 'Deliv.' : status === 'Underdelivery' ? 'Under.' : 'Over.'
    }
  },
  computed: {
    periodIsDaily (): boolean {
      return this.period === 'day'
    },
    periodIsWeekly (): boolean {
      return this.period === 'week'
    },
    periodIsFlight (): boolean {
      return this.period === 'flight'
    },
    pacingSortedByDate (): any {
      const pacing = _.cloneDeep(this.performance?.pacing)
      return pacing ? pacing?.sort((a: any, b: any) => this.parseDate(b.day_tz) - this.parseDate(a.day_tz)) : []
    },
    dailyPacing (): any {
      const latest = this.pacingSortedByDate[0]
      return `${Math.round((1 - latest?.io_satu) * 100)}%`
    },
    prevDay (): any {
      const prev = this.pacingSortedByDate[1]
      return prev?.day_tz
    },
    prevDayPacing (): any {
      const prev = this.pacingSortedByDate[1]
      return `${Math.round((1 - prev?.io_satu) * 100)}%`
    },
    dateList (): any {
      return this.pacingSortedByDate.map((pacing: any) => pacing.day_tz)
    },
    lastSevenDaysPacing (): any {
      // this is how it should work, will return when new data is available
      // const start = this.parseDate(this.performance?.kpi?.last_7_days?.start_period_tz)
      // const end = this.parseDate(this.performance?.kpi?.last_7_days?.end_period_tz)
      // return this.pacingSortedByDate.filter((pacing: any) => this.parseDate(pacing.day_tz) >= start && this.parseDate(pacing.day_tz) <= end)
      return this.pacingSortedByDate.slice(0, 7)
    },
    pacingsOfFlight (): any {
      const start = this.parseDate(this.performance?.kpi?.flight?.start_period_tz)
      const end = this.parseDate(this.performance?.kpi?.flight?.end_period_tz)
      return this.pacingSortedByDate.filter((pacing: any) => this.parseDate(pacing.day_tz) >= start && this.parseDate(pacing.day_tz) <= end)
    },
    averageFlightPacing (): any {
      const pacing = this.pacingsOfFlight
      // sum of all io_spent / sum of all io_obj
      const sumSpent = pacing.reduce((acc: number, curr: any) => acc + curr.io_spent, 0)
      const sumBudget = pacing.reduce((acc: number, curr: any) => acc + curr.io_obj, 0)
      return `${Math.floor((sumSpent / sumBudget) * 100)}%`
    },
    numberOfDaysDelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Delivering').length
    },
    numberOfDaysUnderdelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Underdelivery').length
    },
    numberOfDaysOverdelivering (): any {
      return this.pacingsOfFlight.filter((pacing: any) => pacing.pacing_status === 'Overdelivery').length
    },
    dashboardLink (): any {
      let dates = ''
      if (this.periodIsDaily) {
        dates = 'yesterday'
        // Day+Tz+Date+%282%29=yesterday
      }
      if (this.periodIsWeekly) {
        dates = '7+day'
        // Day+Tz+Date+%282%29=7+day
      }
      if (this.periodIsFlight) {
        const start = new Date(this.performance?.kpi?.flight?.start_period_tz)
        const end = new Date(this.performance?.kpi?.flight?.end_period_tz)
        dates = `${start.getFullYear()}%2F${start.getMonth()}%2F${start.getDate()}+to+${end.getFullYear()}%2F${end.getMonth()}%2F${end.getDay()}`
        // Day+Tz+Date+%282%29=2024%2F11%2F10+to+2024%2F12%2F11
      }
      if (this.pacingSortedByDate[0]?.type_budget === 'imp') {
        // impression dashboard
        return `https://scibids.cloud.looker.com/dashboards/653?Yesterday%27s+pacing=last+day&Line+Item+ID=&Insertion+Order+ID=${this.ioId}&Hour+%26+Timestamp+%28TZ%29+Time=4+day&CUICUI+Status=LIVE&Type+Budget=imp&Day+Tz+Date+%282%29=${dates}`
      }
      // money dashboard
      return `https://scibids.cloud.looker.com/dashboards/632?Yesterday%27s+pacing=last+day&Line+Item+ID=&Insertion+Order+ID=${this.ioId}&CUICUI+Status=LIVE&Type+Budget=money&Day+Tz+Date=${dates}&Last+4+days+pacing=4+day`
    }
  }
})
