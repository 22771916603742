
import { defineComponent } from 'vue'
import { $APPNEXUS, $BEESWAX, $DBM, $META, $THETRADEDESK, $YOUTUBE } from '../../../../config/dspConfig'
import {
  tooltipFunction, tooltipFunctionTarget,
  unknownDemographicConf
} from '@/components/TableComponents/Line/Fragments/DSPOverwriting/dspOverwritingUtils'
import _ from 'lodash'
import { DspOverwritingItem, DspOverwritingValue } from '../../../../types/surcouche_v2_types'
import { InstructionDsp } from '../../../../types/instruction_type'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'
import LabelLine from '@/components/TableComponents/Tools/LabelLine.vue'

type OverwritingData = {
  [key in InstructionDsp]: DspOverwritingItem[]
}

export default defineComponent({
  name: 'IOPageOverviewOverwrites',
  components: { LabelLine },
  props: ['item', 'dsp'],
  data () {
    return {
      loading: false,
      ioId: this.$route.params.external_id,
      priorityOverwriteValues: {
        [$APPNEXUS]: ['overwrite_frequency', 'remove_daily_budget_campaigns_only', 'force_li_daily_pacing_asap', 'remove_min_viz'],
        [$DBM]: ['overwrite_frequency', 'overwrite_daily_budget_li', 'force_pacing_asap_li', 'keep_min_viz'],
        [$THETRADEDESK]: ['overwrite_frequency', 'overwrite_daily_budget_money_adgroup', 'overwrite_daily_budget_impressions_adgroup', 'overwrite_viewability'],
        [$YOUTUBE]: ['overwrite_frequency', 'overwrite_frequency_target', 'overwrite_bid_adjustments_on_device', 'allow_switch_io_pacing'],
        [$BEESWAX]: ['overwrite_frequency'],
        [$META]: ['overwrite_campaign_budget']
      },
      overwriteData: {
        [$APPNEXUS]: [
          {
            label: 'Overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'Overwrite timeparting',
            value: 'remove_time_parting',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'Overwrite budget <span class="overwrite-info-gl-cp">global</span>',
            value: 'remove_daily_budget_global',
            displayRules: (props: any) => {
              return props.remove_daily_budget_global === true
            }
          },
          {
            label: 'Overwrite budget <span class="overwrite-info-gl-cp">campaigns</span>',
            value: 'remove_daily_budget_campaigns_only',
            displayRules: (props: any) => {
              return props.remove_daily_budget_campaigns_only === true
            }
          },
          {
            label: 'Overwrite budget',
            value: '',
            displayRules: (props: any) => {
              return (props.remove_daily_budget_campaigns_only === undefined || props.remove_daily_budget_campaigns_only === false) &&
                (props.remove_daily_budget_global === undefined || props.remove_daily_budget_global === false)
            }
          },
          {
            label: 'Overwrite creative selection',
            value: 'overwrite_creative_selection'
          },
          {
            label: 'Overwrite trusted inventories',
            value: 'keep_trusted_inventory',
            isAdvanced: true,
            reverseBoolean: true
          },
          {
            label: 'Overwrite fold position',
            value: 'remove_fold_position',
            isAdvanced: true
          },
          {
            label: 'Overwrite viewability',
            value: 'remove_min_viz',
            isAdvanced: true
          },
          {
            label: 'Overwrite min completion',
            value: 'remove_min_crate',
            isAdvanced: true
          },
          {
            label: 'Force LI daily pacing ASAP',
            value: 'force_li_daily_pacing_asap',
            isAdvanced: true
          }
        ],
        [$DBM]: [
          {
            label: 'Overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'Overwrite timeparting',
            value: 'keep_timeparting',
            reverseBoolean: true
          },
          {
            label: 'Overwrite viewability',
            value: 'keep_min_viz',
            reverseBoolean: true
          },
          {
            label: 'force pacing asap',
            value: 'force_pacing_asap_li'
          },
          {
            label: 'Overwrite daily budget li',
            value: 'overwrite_daily_budget_li'
          },
          {
            label: 'Remove fold position',
            value: 'remove_fold_position'
          },
          {
            label: 'Overwrite creative selection',
            value: 'overwrite_creative_selection'
          },
          {
            label: 'automatically Remove deprecated url',
            value: 'automatically_remove_deprecated_url'
          },
          unknownDemographicConf
        ],
        [$THETRADEDESK]: [
          {
            label: 'Overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'Remove timeparting',
            value: 'remove_timeparting'
          },
          {
            label: 'Remove fold position',
            value: 'remove_fold_position'
          },
          {
            label: 'Overwrite daily budget money adgroup',
            value: 'overwrite_daily_budget_money_adgroup'
          },
          {
            label: 'Overwrite daily budget impressions adgroup',
            value: 'overwrite_daily_budget_impressions_adgroup'
          },
          {
            label: 'Overwrite viewability',
            value: 'overwrite_viewability'
          }
        ],
        [$YOUTUBE]: [
          {
            label: 'Overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          },
          {
            label: 'Overwrite frequency target',
            value: 'overwrite_frequency_target',
            tooltip: tooltipFunctionTarget,
            isOrange: tooltipFunctionTarget
          },
          {
            label: 'Overwrite li budget',
            value: 'overwrite_li_budget'
          },
          {
            label: 'automatically Remove deprecated url',
            value: 'automatically_remove_deprecated_url'
          },
          {
            label: 'Overwrite bid adjustments on device',
            value: 'overwrite_bid_adjustments_on_device'
          },
          {
            label: 'Remove time parting',
            value: 'remove_time_parting'
          },
          unknownDemographicConf,
          {
            label: 'Allow switch IO pacing to ASAP',
            value: 'allow_switch_io_pacing'
          }
        ],
        [$BEESWAX]: [
          {
            label: 'Overwrite frequency',
            value: 'overwrite_frequency',
            tooltip: tooltipFunction,
            isOrange: tooltipFunction
          }
        ],
        [$META]: [
          {
            label: 'Overwrite campaign budget',
            value: 'overwrite_campaign_budget'
          }
        ]
      } as OverwritingData,
      defaultItem: {
        id: '',
        group_name: '',
        advertiser_id: '',
        member_id: '',
        retry: false,
        fast_retry: false,
        is_active: true,
        opti_auto: false,
        otto_min_viz: false,
        reactivate_otto: false,
        objective: {
          KPI_CPA: '',
          max_CPM: '',
          min_viz: '',
          min_video_viz: '',
          offset: ''
        },
        use_opti_ratio_surcouche: false,
        opti_ratio: '',
        constraints_io: {
          margin_daily: '',
          margin_period: '',
          max_A: '',
          max_cutoff: '',
          mode: '',
          max_division: '',
          min_division: '',
          no_division: ''
        },
        revenue_type: '',
        true_conversion_funnel_pixel_ids: {},
        true_KPI_to_optimize: '',
        KPI_to_optimize: '',
        true_kpi_pixel_ids: '',
        optimize_campaigns: 'all',
        optimize_lineitems: 'all',
        // appnexus
        remove_daily_budget_global: false,
        remove_daily_budget_campaigns_only: false,
        remove_min_crate: true,
        remove_min_viz: true,
        keep_trusted_inventory: false,
        force_li_daily_pacing_asap: true,
        // appnexus
        remove_fold_position: true,
        overwrite_creative_selection: false,
        remove_time_parting: false,
        // dbm / mediamath
        keep_frequency: false,
        keep_budget_strat: false,
        // dbm
        keep_timeparting: false,
        keep_min_viz: false,
        force_pacing_asap_li: true,
        overwrite_daily_budget_li: true,
        executor_version: '1',
        use_custom_algo: true,
        // ttd
        overwrite_frequency: true,
        overwrite_daily_budget_impressions_adgroup: false,
        overwrite_daily_budget_money_adgroup: false,
        overwrite_viewability: false,
        // mediamath / ttd
        remove_timeparting: true,
        remove_budget_strat_money: true,
        remove_budget_strat_imp: true,
        force_pacing_asap_money: false,
        force_pacing_asap_imp: true,
        // facebook
        use_adset_budgets: false,
        // youtube
        overwrite_frequency_target: true,
        overwrite_bid_adjustments_on_device: true,
        allow_switch_io_pacing: false,
        // youtube / dbm
        automatically_remove_deprecated_url: true,
        overwrite_li_budget: true,
        overwrite_unknown_demographic: 'never',
        waterfall_priority: 'delivery_first',
        KPI_rolling_period: null,
        // meta
        business_manager_id: '',
        ad_account_id: '',
        overwrite_campaign_budget: true,
        //
        obj_watcher: {
          granularity: 'insertion_order',
          CPA: {
            value: ''
          // sensibility: 0.3,
          // pixel_id: null
          },
          saturation: {
          // sensibility: 0.2,
            io_alert: 'FAUX'
          },
          margin: {
            value: ''
          // sensibility: 0.3
          },
          min_viz: {
            value: ''
          // sensibility: 0.3
          },
          min_CPM: {
            value: ''
            // sensibility: 0.3
          },
          max_CPM: {
            value: ''
            // sensibility: 0.3
          },
          min_crate: {
            value: ''
          },
          KPI_to_optimize: {
            value: '',
            KPI: ''
          },
          true_KPI_to_optimize: {
            value: '',
            KPI: ''
          }
        },
        true_conv_measurement_tool: null,
        true_pivot_variable: null,
        strat_lvl: 'standard',
        p3_obj_watcher: {
          kpi_type: null,
          measurement_source: null,
          min_target_value: null,
          max_target_value: null,
          conversion_funnel_pixel_ids: null
        },
        p3_obj_watcher_alpha: 0.5,
        p3_obj_watcher_pivot_variables: [],
        optimize_insertion_orders: 'all',
        remove_frequency_global: false,
        remove_frequency_campaigns_only: false,
        remove_frequency_li_ca_only: false,
        matching_cp: false,
        matching_li: false,
        insertion_date: null,
        conv_measurement_tool: null,
        partner_id: null
      }
    }
  },
  methods: {
    isOn (item: any) {
      if (isNotNullOrUndefined(item.trueValue)) {
        return item.value === item.trueValue
      }
      return item.value
    }
  },
  computed: {
    priorityOverwriteData (): DspOverwritingItem[] {
      if (!isNotNullOrUndefined(this.dsp)) {
        return []
      }
      return this.overwriteData[this.dsp as InstructionDsp].filter((item: any) => {
        return this.priorityOverwriteValues[this.dsp].includes(item.value)
      })
    },
    nonPriorityOverwriteData (): DspOverwritingItem[] {
      if (!isNotNullOrUndefined(this.dsp)) {
        return []
      }
      return this.overwriteData[this.dsp as InstructionDsp].filter((item: any) => {
        return !this.priorityOverwriteValues[this.dsp].includes(item.value)
      })
    },
    leftColumnOverwrites (): DspOverwritingValue[] {
      let prio = _.cloneDeep(this.priorityOverwriteData)
      let nonPrio = _.cloneDeep(this.nonPriorityOverwriteData)
      while (nonPrio.length > prio.length) {
        prio.push(nonPrio[0])
        nonPrio.shift()
      }
      let overwrites = _.cloneDeep(this.overwrites)
      let leftOverwrites = overwrites.filter((item: any) => {
        return prio.map((item: any) => item.label).includes(item.label)
      })
      return leftOverwrites
    },
    rightColumnOverwrites (): DspOverwritingValue[] {
      // filter all the non priority Overwrite data where the value is not in an item on the left column
      return this.overwrites.filter((item: any) => {
        return !this.leftColumnOverwrites.map((item: any) => item.label).includes(item.label)
      })
    },
    overwrites (): DspOverwritingValue[] {
      console.log('trying to get overwrites for dsp', this.dsp)
      if (!isNotNullOrUndefined(this.dsp)) {
        return []
      }
      let dsp: InstructionDsp = this.dsp as InstructionDsp

      if (Object.keys(this.overwriteData).indexOf(dsp) === -1) {
        console.warn(`The dsp ${dsp} is not configured in overwriteData. No Overwrite data will be displayed.`)
        return []
      }

      let filteredOverwrited = _.cloneDeep(this.overwriteData[dsp]).filter(item => item.displayRules === undefined || item.displayRules(this.item))

      return filteredOverwrited.map(item => {
        let tooltip = false
        let isOrange = false
        if (typeof item.tooltip === 'function') {
          tooltip = item.tooltip({ item: this.item })
        }
        if (typeof item.isOrange === 'function') {
          isOrange = item.isOrange({ item: this.item })
        }

        const trueValue = item.trueValue ? item.trueValue : null

        if (this.item[item.value] === undefined || this.item[item.value] === null) {
          return {
            label: item.label,
            value: !!item.reverseBoolean,
            isAdvanced: item.isAdvanced,
            tooltip: tooltip,
            isOrange: isOrange,
            trueValue: trueValue
          } as DspOverwritingValue
        }
        return {
          label: item.label,
          value: item.reverseBoolean ? !this.item[item.value] : this.item[item.value],
          isAdvanced: item.isAdvanced,
          tooltip: tooltip,
          isOrange: isOrange,
          trueValue: trueValue
        } as DspOverwritingValue
      })
    }
  }
})
