
import { defineComponent } from 'vue'
import { isNotNullOrUndefined } from '../../../../utils/commonUtils'

export default defineComponent({
  name: 'IOPagePerformanceConstraints',
  props: ['performance', 'ioDatastore', 'period'],
  methods: {
    noConstraint (value: string): boolean {
      return value.includes('No constraint')
    },
    formatNumbers (nb: number): string {
      if (!isNotNullOrUndefined(nb) || isNaN(nb)) {
        return '-'
      }
      if (Math.abs(nb) < 10) {
        return (nb).toFixed(2)
      }
      if (Math.abs(nb) < 1000) {
        return Math.floor(nb).toString()
      }
      if (Math.abs(nb) < 1000000) {
        // if the number is a round thousand, we don't want to display the decimal
        if (Math.floor(nb / 1000) * 10 === Math.floor(nb / 100)) {
          return Math.floor(nb / 1000).toString() + 'K'
        }
        return Math.floor(nb / 100) / 10 + 'K'
      }
      if (Math.abs(nb) < 1000000000) {
        // if the number is a round million, we don't want to display the decimal
        if (Math.floor(nb / 10000000) * 100 === Math.floor(nb / 100000)) {
          return Math.floor(nb / 1000000).toString() + 'M'
        }
        return Math.floor(nb / 10000) / 100 + 'M'
      }
      if (Math.abs(nb) >= 1000000000) {
        // if the number is a round billion, we don't want to display the decimal
        if (Math.floor(nb / 10000000000) * 100 === Math.floor(nb / 100000000)) {
          return Math.floor(nb / 1000000000).toString() + 'B'
        }
        return (nb / 1000000000).toFixed(2) + 'B'
      }
    }
  },
  computed: {
    kpiFromPeriod (): any {
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday
        case 'week':
          return this.performance?.kpi?.last_7_days
        case 'flight':
          return this.performance?.kpi?.flight
        default:
          return this.performance?.kpi?.yesterday
      }
    },
    kpiFromPreviousPeriod (): any {
      switch (this.period) {
        case 'day':
          return this.performance?.kpi?.yesterday_minus_1
        case 'week':
          return this.performance?.kpi?.last_7_days_minus_1
        case 'flight':
          return this.performance?.kpi?.flight_minus_1
        default:
          return this.performance?.kpi?.yesterday_minus_1
      }
    },
    displayCPM (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.max_CPM) || isNotNullOrUndefined(this.ioDatastore?.constraints_io?.min_CPM) || isNotNullOrUndefined(this.kpiFromPeriod?.cpm)
    },
    minCPM (): any {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.min_CPM) ? this.ioDatastore.constraints_io.min_CPM : '-'
    },
    maxCPM (): any {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.max_CPM) ? this.ioDatastore.constraints_io.max_CPM : '-'
    },
    minMaxCPM (): any {
      return this.minCPM !== '-' || this.maxCPM !== '-' ? `${this.minCPM} / ${this.maxCPM}` : 'No constraint on CPM'
    },
    displayMinViz (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.obj_watcher?.min_viz) || isNotNullOrUndefined(this.kpiFromPeriod?.viewability)
    },
    minViz (): any {
      return isNotNullOrUndefined(this.ioDatastore?.obj_watcher?.min_viz) ? `${this.formatNumbers(this.ioDatastore?.obj_watcher?.min_viz * 100)}%` : 'No constraint on viewablility'
    },
    displayMargin (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.margin_daily) || isNotNullOrUndefined(this.kpiFromPeriod?.margin)
    },
    expectedMargin (): any {
      return isNotNullOrUndefined(this.ioDatastore?.constraints_io?.margin_daily) ? `${this.ioDatastore?.constraints_io?.margin_daily * 100}%` : 'No constraint on margin'
    },
    displayFrequency (): boolean {
      return isNotNullOrUndefined(this.ioDatastore?.max_frequency?.exposures) || isNotNullOrUndefined(this.kpiFromPeriod?.frequency)
    },
    expectedFrequency (): any {
      const freq = this.ioDatastore?.max_frequency
      return isNotNullOrUndefined(freq?.exposures) ? `Max <strong>${freq.exposures}</strong> imp(s) per <strong>${freq.amount}</strong> <strong>${freq.period}</strong>` : 'No constraint on frequency'
    },
    cpm (): any {
      return this.kpiFromPeriod?.cpm ? this.formatNumbers(this.kpiFromPeriod.cpm) : '-'
    },
    cpmCompareIcon (): any {
      const currentValue = this.kpiFromPeriod?.cpm
      const prevValue = this.kpiFromPreviousPeriod?.cpm
      return prevValue > currentValue ? 'arrow_drop_up' : prevValue < currentValue ? 'arrow_drop_down' : 'horizontal_rule'
    },
    cpmComparedToPrevious (): any {
      return `${this.formatNumbers((this.kpiFromPreviousPeriod?.cpm - this.cpm) / this.kpiFromPreviousPeriod?.cpm * 100)}%`
    },
    isCpmOutOfBounds (): boolean {
      return (this.cpm !== '-' && ((this.minCPM !== '-' && this.cpm < this.minCPM) || (this.maxCPM !== '-' && this.cpm > this.maxCPM)))
    },
    viewability (): any {
      return this.kpiFromPeriod?.viewability ? `${this.formatNumbers(this.kpiFromPeriod?.viewability * 100)}%` : '-'
    },
    viewabilityCompareIcon (): any {
      const currentValue = this.kpiFromPeriod?.viewability
      const prevValue = this.kpiFromPreviousPeriod?.viewability
      return prevValue > currentValue ? 'arrow_drop_up' : prevValue < currentValue ? 'arrow_drop_down' : 'horizontal_rule'
    },
    viewabilityComparedToPrevious (): any {
      return `${this.formatNumbers((this.kpiFromPreviousPeriod?.viewability - this.kpiFromPeriod?.viewability) / this.kpiFromPreviousPeriod?.viewability)} pts`
    },
    isViewRateUnderExpected (): boolean {
      return !this.noConstraint(this.minViz) && this.viewability !== '-' && this.viewability < this.minViz
    },
    margin (): any {
      return `${this.formatNumbers(this.kpiFromPeriod?.margin * 100)}%`
    },
    isMarginUnderExpected (): boolean {
      return !this.noConstraint(this.expectedMargin) && this.margin !== '-' && this.margin < this.expectedMargin
    },
    frequency (): any {
      return this.formatNumbers(this.kpiFromPeriod?.frequency)
    },
    isFrequencyOverExpected (): boolean {
      return !this.noConstraint(this.expectedFrequency) && this.frequency !== '-' && this.frequency > this.ioDatastore?.max_frequency?.exposures
    }
  }
})
